import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaMapLocationDot } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <>
      <div className='footer-bg foot-pad'>
        <Container>
          <Row>
            <Col lg='3' className='py-3'>
                <h3 className='bold py-3'> Quick Links</h3>
                <ul className='list-unstyled'>
                  <li><Link to='/'>Products</Link></li>
                  <li><Link to='/safetytips'>Safety Tips</Link></li>
                  <li><Link to='/contactus'>Contact Us</Link></li>
                </ul>
            </Col>
            <Col lg='9'  className='py-3 align-self-center'>
              <div className='rules'>
                As per 2018 supreme court order, online sale of firecrackers are not permitted! We value our customers and at the same time, respect jurisdiction. We request you to add your products to the cart and submit the required crackers through the enquiry button. We will contact you within 24 hrs and confirm the order through WhatsApp or phone call. Please add and submit your enquiries and enjoy your Diwali with Crackers Name. Our License No.----. Crackers Name as a company following 100% legal & statutory compliances and all our shops, go-downs are maintained as per the explosive acts. We send the parcels through registered and legal transport service providers as like every other major companies in Sivakasi is doing so.
              </div>
            </Col>
            <Col lg='12'  className='border-top border-bottom py-4'>
                <Row>
                  <Col lg='3' className="align-self-center  py-3">
                      <div className='ft-logo mx-auto'>
                        <img src={require('../assets/images/storelogo.png')} className='img-fluid' alt='product name' />
                      </div>
                  </Col>
                  <Col lg='3' className="align-self-center py-3">
                    <div className='d-flex align-items-center'>
                      <div className='ft-icon'>
                          <FaMapLocationDot />
                      </div>
                      <div className='ft-details'>
                      4/222A , SIVAKASI CORNER ROAD, ETTURVATTAM,<br/>
                      E.MUTHULINGAPURAM,SATTUR ROAD, SIVAKASI
                      </div>
                    </div>
                  </Col>
                  <Col lg='3' className="align-self-center py-3">
                  <div className='d-flex align-items-center'>
                      <div className='ft-icon'>
                        <MdMail />
                      </div>
                      <div className='ft-details'>
                          Email : akmsenthurpyrotech@gmail.com
                      </div>
                    </div>
                  </Col>
                  <Col lg='3' className="align-self-center py-3">
                    <div className='d-flex align-items-center'>
                      <div className='ft-icon'>
                         <FaPhoneAlt />
                      </div>
                      <div className='ft-details'>
                        <div>+91 97905 03211</div>
                        <div>+91 63855 18781</div>
                        <div>+91 96003 03225</div>
                      </div>
                    </div>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </div>
      </>
      <>
      <div className='mark-bg'>
          <Container>
            <Row>
              <Col lg='12' className='py-3'>
                <div className='text-center regular'> Copyright © {currentYear},.AKM SENTHUR PYRO TECH Crackers. All rights reserved </div>
              </Col>
            </Row>
          </Container>
      </div>
      </>
    </>
  )
}

export default Footer