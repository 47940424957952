import React from 'react'
import { Close } from './Buttons'
import { FaTimes } from "react-icons/fa";
import'./pagetitle.css'
const PageTitle = ( {title,onClick}) => {
  return (
    <div className='d-flex justify-content-between p-3 title-bg'>
        <div className='regular text-black'>{title}</div>
        <div>
            <Close label={<><FaTimes /></>} onClick={onClick}/>
        </div>
    </div>
  )
}

export default PageTitle