import './App.css';
import'./pages/Pages.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Products from './pages/Products';
import ContactUs from './pages/ContactUs';
import SafetyTips from './pages/SafetyTips';
import OrderPreview from './pages/OrderPreview';
import Billpreview from './pdf/Billpreview';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Products/>}/> 
            <Route path='/safetytips' element={<SafetyTips/>}/> 
            <Route path='/contactus' element={<ContactUs/>}/>
            <Route path='/orderpreview' element={<OrderPreview/>}/>
            <Route path='/nopage' element={<Billpreview/>}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
