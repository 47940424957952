import React from 'react';
import MetaTags from './MetaTags';
import AppBar from './AppBar'
import { Container,Row,Col } from 'react-bootstrap'
import { PiMapPinSimpleAreaBold } from "react-icons/pi";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import Mark from './Mark';
const ContactUs = () => {
  return (
    <div>
       <MetaTags
        title="Contact Us - AKM SENTHUR PYRO TECH Crackers - Whole sale Crackers"
        type="website"
        siteName="AKM SENTHUR PYRO TECHcrackers.com"
        url="https://akmsenthurcrackers.com/contactus"
        keywords="Contact Us | AKM SENTHUR PYRO TECH Crackers "
        description="Contact for wholesale Crackers and Retail Crackers"
        revisitAfter="10 Days"
      />
      <AppBar/>
      <img src={require('../assets/images/b3.jpg')} className='img-fluid w-100' alt='product name' />
        <Container className='padding'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className='py-3'>
                <div className='bold  text-center'> Contact Us</div>
              </Col>
              <Col lg="4">
                <div className='box'>
                    <div className='box-icon'>
                      <PiMapPinSimpleAreaBold size={40} color={'#fff'} />
                    </div>
                    <div className='regular'>
                    4/222A , SIVAKASI CORNER ROAD, ETTURVATTAM,<br/>
                    E.MUTHULINGAPURAM,SATTUR ROAD, SIVAKASI
                    </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='box'>
                    <div className='box-icon'>
                    <BiPhoneCall size={40} color={'#fff'}/>
                    </div>
                    <div>
                        <div>+91 97905 03211</div>
                        <div>+91 63855 18781</div>
                        <div>+91 96003 03225</div>
                    </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='box'>
                    <div className='box-icon'>
                       <MdOutlineAlternateEmail size={40} color={'#fff'}/>
                    </div>
                    <div className='pb-4'>
                       <div>akmsenthurpyrotech@gmail.com</div> 
                    </div>
                </div>
              </Col>
            </Row>
        </Container>
        <>
        
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3936.4974669452554!2d77.90941377502314!3d9.377659790698253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwMjInMzkuNiJOIDc3wrA1NCc0My4yIkU!5e0!3m2!1sen!2sin!4v1722800551190!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          title="Google Maps Embed of Sivakasi, Tamil Nadu"
        />
        </>
      
        <>
        <Mark/>
        </>
    </div>
  )
}

export default ContactUs