import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
const Mark = () => {
    const currentYear = new Date().getFullYear();
  return (
    <>
      <div className='mark-bg'>
          <Container>
            <Row>
              <Col lg='12' className='py-3'>
                <div className='text-center regular'> Copyright © {currentYear},.AKM SENTHUR PYRO TECH Crackers. All rights reserved </div>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  )
}

export default Mark